<template>
  <div class="formTitleComponent">
    <div class="agedati-background form_div_pre_title"> </div>
  </div>
</template>

<script>
export default {
  name: 'formTitleComponent',
}
</script>
<style>
.agedati-background {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background: linear-gradient(to right, #454F59, #699FB8);
}
.form_div_pre_title {
  height: 120px;
}
</style>
